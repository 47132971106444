<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites v-model="queryForm.websites"></form-item-sites>
					<form-item>
						<el-button type="primary" @click="searchEvent" icon="el-icon-search" size="mini">查询</el-button>
						<el-button type="primary" size="mini" @click="downloadTask">下载</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="测款类型:">
						<el-select v-model="queryForm.source" size="mini" :clearable="true">
							<el-option v-for="item in sources" :label="item.label" :value="item.key" :key="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="选择日期:">
						<el-date-picker v-model="queryForm.date" type="daterange" size="mini" :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</form-item>
					<form-item label="商品id:">
						<el-input v-model.trim="queryForm.productId" size="mini"></el-input>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" default-expand-all row-key="row_id" grouped="true" :span-method="objectSpanMethod" border>
				<el-table-column label="测款日期" min-width="150">
					<template slot-scope="scope">{{scope.row.adCreatedAtTimeStamp | formatDate('YYYY-MM-DD')}}</template>
				</el-table-column>
				<el-table-column label="站点" prop="website" min-width="75">
				</el-table-column>
				<el-table-column label="国家" prop="countryCode" min-width="75">
				</el-table-column>
				<el-table-column label="AD ID" prop="adId" min-width="150">
				</el-table-column>
				<el-table-column label="广告搭配id" min-width="150">
					<template slot-scope="scope">
						{{scope.row.spuImages && scope.row.spuImages[0] && scope.row.spuImages[0].spu}}
					</template>
				</el-table-column>
				<el-table-column label="广告搭配组" min-width="150">
					<template slot-scope="scope">
						<el-popover
							v-if="scope.row.spuImages && scope.row.spuImages[0] && scope.row.spuImages[0].image"
						    width="300"
						    trigger="hover">
							<img class="w-100 h-100" width="200" height="200" style="object-fit:cover;" :src="scope.row.spuImages[0].image" alt="">
						    <div slot="reference" style="width: 100px;height: 100px;" class="border">
						    	<img class="w-100 h-100" style="object-fit:cover;" :src="scope.row.spuImages[0].image" alt="">
						    </div>
						  </el-popover>
					</template>
				</el-table-column>
				<el-table-column label="测款时间" min-width="230" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.adCreatedAt}}</span>
						<template v-if="scope.row.endAt">
							<br>
							<span>~</span>
							<br>
							<span>{{scope.row.endAt}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column label="ad_cost" prop="spend" min-width="75">
				</el-table-column>
				<el-table-column label="ad_imp" prop="impressions" min-width="75">
				</el-table-column>
				<el-table-column label="ad_click" prop="inlineLinkClicks" min-width="75">
				</el-table-column>
				<el-table-column label="ad_ctr" min-width="75">
					<template slot-scope="scope">
						<span v-if="scope.row.inlineLinkClickCtr">{{Number(scope.row.inlineLinkClickCtr).toFixed(2)}}%</span>
					</template>
				</el-table-column>
				<el-table-column label="ad_cpm" min-width="75">
					<template slot-scope="scope">
						{{Number(scope.row.cpm).toFixed(2)}}
					</template>
				</el-table-column>
				<el-table-column label="ad_cpc" min-width="75">
					<template slot-scope="scope">
						{{Number(scope.row.costPerInlineLinkClick).toFixed(2)}}
					</template>
				</el-table-column>
				<el-table-column label="ad_add to cart" prop="addToCartValue" min-width="75">
					<template slot-scope="scope">
						{{scope.row.addToCartValue || 0}}
					</template>
				</el-table-column>
				<el-table-column label="ad_checkout" prop="checkoutValue" min-width="75">
					<template slot-scope="scope">
						{{scope.row.checkoutValue || 0}}
					</template>
				</el-table-column>
				<el-table-column label="order" prop="orderValue" min-width="75">
				</el-table-column>
				<el-table-column label="rate" min-width="75">
					<template slot-scope="scope">
						<!-- orderValue/inlineLinkClicks -->
						{{Number(scope.row.rate).toFixed(2)}}
					</template>
				</el-table-column>
				<el-table-column label="gmv" prop="gmv" min-width="75">
				</el-table-column>
				<el-table-column label="roi" min-width="75">
					<template slot-scope="scope">
						<!-- gmv/spend -->
						{{Number(scope.row.roi).toFixed(2)}}
					</template>
				</el-table-column>
				<el-table-column label="spu" prop="spu" min-width="150">
				</el-table-column>
				<el-table-column label="主站spu" prop="spuInHekkaMall" min-width="150">
				</el-table-column>
				<el-table-column label="商品图片" min-width="150">
					<template slot-scope="scope">
						<el-popover
							v-if="scope.row.image"
						    width="300"
						    trigger="hover">
							<img class="w-100 h-100" width="200" height="200" style="object-fit:cover;" :src="scope.row.image" alt="">
						    <div slot="reference" style="width: 100px;height: 100px;" class="border">
						    	<img class="w-100 h-100" style="object-fit:cover;" :src="scope.row.image" alt="">
						    </div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column label="sales" min-width="75">
					<template slot-scope="scope">
						{{scope.row.sales || '0'}}
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import FormItemSites from '@/components/form-items/form-item-sites.vue'
	import {
		mapActions
	} from 'vuex'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
			FormItemSites
		},
		data() {
			return {
				q: {
					createdMinStr: Moment().add(-6, 'days').format('YYYY-MM-DD'),
					createdMaxStr: Moment().add(-0, 'days').format('YYYY-MM-DD')
				},
				queryForm: {
					websites: [],
					productId: '',
					date: [
						Moment().add(-6, 'days').format('YYYY-MM-DD'),
						Moment().add(-0, 'days').format('YYYY-MM-DD')
					],
					source: null
				},
				tableData: []
			}
		},
		computed: {
			sources() {
				return [{
					key: 1,
					label: '基础测款'
				}, {
					key: 2,
					label: '进阶测款'
				}]
			}
		},
		methods: {
			...mapActions([
				'fetchNewtestList',
				'exportNewtestList'
			]),
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdMinStr: this.queryForm.date[0],
					createdMaxStr: this.queryForm.date[1]
				}
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const _params = {
					...this.page,
					...this.q
				}
				const params = {}
				Object.keys(_params).forEach(key => {
					if (_params[key] !== '') {
						params[key] = _params[key]
					}
				})
				return this.fetchNewtestList({
					params,
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			    if (columnIndex <= 18) {
					const statistics = row.statistics || []
					const rowspan = Array.isArray(row.statistics) ? Math.max(statistics.length, 1) : 0
			        return {
			        	rowspan,
			        	colspan: 1
			        }
				}
			},
			downloadTask() {
				this.$store.commit('download-manager/addTask', {
					action: `exportNewtestList`,
					payload: {...this.q},
					title: `NEW_TEST(${this.q.createdMinStr}~${this.q.createdMaxStr})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			}
		}
	}
</script>

<style>
</style>
